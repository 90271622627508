import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

import play from "../../images/icons/play-white.svg"

const BackgroundSection = ({ className }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "home/2.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        // Set ImageData.
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
            backgroundColor={`#040e18`}
          >
            <ContentWrapper>
              <button type="button" onClick={() => console.log("play")}>
                <a
                  href="https://www.youtube.com/watch?v=oqawK33RkPE&feature=youtu.be"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={play} alt="Play vídeo" />
                </a>
              </button>
              <h2>Nova sede</h2>
              <p>
                Alcance seu público de forma assertiva com tudo o que as
                plataformas digitais têm a oferecer. Somos especializados em
                transmissão ao vivo em todos os segmentos de eventos.
              </p>
            </ContentWrapper>
          </BackgroundImage>
        )
      }}
    />
  )
}

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;

  height: 100vh;

  button {
    background: none;
    cursor: pointer;
    border: none;
    margin-bottom: 20px;
  }

  h2 {
    color: white;
    font-size: 94px;
    line-height: 90px;
    font-weight: lighter;
    font-family: "CircularStd", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    max-width: 800px;
  }

  p {
    font-size: 22px;
    color: white;
    max-width: 545px;
    padding-left: 6px;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  padding-bottom: 100px;

  @media (max-width: 1250px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 1000px) {
    button {
      margin-bottom: 10px;
    }

    h2 {
      font-size: 50px;
      line-height: 50px;
      padding-bottom: 10px;
    }

    p {
      font-size: 20px;
    }
  }

  @media (max-width: 800px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    button {
      margin-top: 10px;
      align-self: flex-end;
      order: 1;
    }
  }

  @media (max-width: 375px) {
    padding-bottom: 20px;
    h2 {
      font-size: 41px;
      line-height: 41px;
      padding-bottom: 10px;
    }

    p {
      font-size: 18px;
      padding-left: 0px;
    }
  }

  @media (max-width: 320px) {
    h2 {
      font-size: 33px;
      line-height: 33px;
      padding-bottom: 10px;
    }

    p {
      font-size: 16px;
      padding-left: 0px;
    }
  }
`

export default StyledBackgroundSection
